



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IMessageCreate, IUserService } from '@/interfaces';
import VTextAreaWithValidation from '@/components/VTextAreaWithValidation.vue';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import VSelectWithValidation from '@/components/VSelectWithValidation.vue';
import VFileInputWithValidation from '@/components/VFileInputWithValidation.vue';
import { ValidationProvider } from 'vee-validate';
import {
  dispatchGetUserProfile,
  readUserProfile,
  dispatchCreateMessage,
  dispatchUploadReceiversFile,
} from '@/store/main/accessors';

@Component({
  components: {
    VFileInputWithValidation,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VTextAreaWithValidation,
  },
})
export default class SendMessage extends Vue {
  public valid = false;
  public service: string = '';
  public sender: string = '';
  public receivers: string = '';
  public receiversFile: File | null = null;
  public text: string | null = null;
  public imageUrl: string | null = null;
  public servicesList = [];
  public checkSender: boolean = false;
  public sendersList = [];
  public services;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    await dispatchGetUserProfile(this.$store);
    const user = readUserProfile(this.$store);
    if (user) {
      this.services = new Map<string, IUserService>(
        user.services.map((item) => [item.service, item] as [string, IUserService]),
      );
      this.servicesList = Array.from(this.services.keys());
      await this.reset();
    }
  }

  public async reset() {
    this.service = '';
    this.text = '';
    this.receivers = '';
    this.sender = '';
    this.receiversFile = null;
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      let receiversFileKey = '';
      if (this.receiversFile) {
        const uploadReceiversFileResponse = await dispatchUploadReceiversFile(this.$store, this.receiversFile);
        receiversFileKey = uploadReceiversFileResponse.key;
      }
      const message: IMessageCreate = {
        text: this.text,
        image_url: this.imageUrl,
        service: this.service,
        sender: this.sender,
        receiver: this.receivers.split('\n').filter((row) => row.trim()),
        receivers_file_key: receiversFileKey,
      };
      const result = await dispatchCreateMessage(this.$store, message);
      if (result.queued.length > 0) {
        this.$router.push('/main/messages/all?id=' + result.queued.join(','));
      }
    }
  }

  @Watch('service')
  public setSendersList() {
    this.sender = '';
    this.sendersList = this.services.get(this.service).allowed_senders;
    this.checkSender = this.services.get(this.service).check_sender;
    this.$refs.observer.reset();
  }
}
